<template>
  <b-modal
    v-model="showUser"
    centered
    :title="(useDefault ? '添加被' : '添加') + '审核单位'"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label
        >{{ ksInfo.ksmc }}{{ useDefault ? "/" + defaultDw.dwmc : "" }}</label
      >
    </div>
    <div class="d-flex check-distri mb-3" v-if="!useDefault">
      <label style="width: 25%">输入自定义审核单位名称</label>
      <div class="d-flex check-distri ">
        <input v-model="dwmc" type="text" class="form-control w-100 h30" />
      </div>
    </div>
    <div class="table-responsive border mt-3">
      <el-checkbox-group
        v-model="chooseOrgPerson"
        style="max-height: 300px; overflow-y: auto"
      >
        <table class="table light-table table-hover">
          <thead class="thead-light">
            <tr>
              <th style="width: 5%">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  class="mr-1"
                  @change="selectAll"
                >
                </el-checkbox
                >全选
              </th>
              <th style="width: 10%">机构名称</th>
              <th style="width: 10%">单位编号</th>
              <th style="width: 10%">单位名称</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, index) in tableList" :key="index">
              <td>
                <el-checkbox :label="obj" class="mr-1">{{
                  index + 1
                }}</el-checkbox>
              </td>
              <td>{{ obj.jgmc }}</td>
              <td>
                {{ obj.dwdm }}
              </td>
              <td>{{ obj.dwmc }}</td>
            </tr>
          </tbody>
        </table>
      </el-checkbox-group>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addDep">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getDicts} from "@/api/common/dict.js";
import {getDepartment} from "@/api/admin/exam/checkPower";
import {addCheckPowerUnitsDiyBatch} from "@/api/admin/exam/checkPower";

export default {
  props: {
    useDefault: {
      default: false,
    },
    defaultDw: {},
    ksInfo: {
      default: {}
    },
  },
  data() {
    return {
      checkAll: false,
      chooseChecks: [],
      chooseOrgPerson: [],
      checks: [
        {name: "报名资格审核", id: 1},
        {name: "报名照片审核", id: 2},
        {name: "学历审核", id: 3},
        {name: "导出报名审核", id: 4},
        {name: "修改审核结果", id: 5},
        {name: "锁定审核", id: 6},
      ],
      isIndeterminate: false,
      search: [],
      pageData: {
        jgdm: "",
      },
      dict: {},
      tableList: [],
      showUser: false,
      dwmc: "",
    };
  },
  methods: {
    show(){
      this.chooseOrgPerson = []
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    selectAll(e) {
      this.chooseOrgPerson = e ? this.tableList : [];
      this.isIndeterminate = e;
    },
    getList() {
      getDepartment(this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.data.total;
        }
      });
    },
    addDep() {
      let params = [];
      this.chooseOrgPerson.forEach((item) => {
        if (this.useDefault) {
          params.push({
            ksbmbh: this.ksInfo.ksbmbh,
            ksmc: this.ksInfo.ksmc,
            dwdm: this.defaultDw.dwdm,
            dwmc: this.defaultDw.dwmc,
            jgdm: this.defaultDw.jgdm,
            jgmc: this.defaultDw.jgmc,
            bshdwdm: item.dwdm,
            bshdwmc: item.dwmc,
            bshjgdm: item.jgdm,
            bshjgmc: item.jgmc,
          });
        } else {
          params.push({
            ksbmbh: this.ksInfo.ksbmbh,
            ksmc: this.ksInfo.ksmc,
            dwdm: this.pageData.dwdm,
            dwmc: this.pageData.dwmc,
            jgdm: this.pageData.jgdm,
            jgmc: this.pageData.jgmc,
            bshdwdm: item.dwdm,
            bshdwmc: item.dwmc,
            bshjgdm: item.jgdm,
            bshjgmc: item.jgmc,
          });
        }
      });
      if (!this.dwmc){
          this.$notify.warning("请输入审核单位名称");
          return;
      }
      addCheckPowerUnitsDiyBatch(this.dwmc, params).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
          this.hide()
          this.$emit("success");
        }
      });
    },
  },
  components: {
  },
  mounted() {
    this.getList();
    getDicts("shdw").then((res) => {
      this.dict = res;
      this.search = [
        {
          title: "机构/单位",
          bound: ["dwdm"],
          type: "select",
          dict: JSON.parse(JSON.stringify(res.shdw)),
        }
      ];
    });
  }
};
</script>

<style></style>
