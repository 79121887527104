<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
  .modal-title /deep/ {
    text-align: center !important;
    width: 100% !important;
    padding: auto !important;
  }

  /deep/ .el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getCheckPowerUnitsPage,
    deleteCheckPowerUnitsByDwdm,
    deleteAll
  } from "@/api/admin/exam/checkPower";
  import addDepartment from "./add-department";
  import addDepartmentDiy from "./add-department-diy";
  import deleteDepartment from "./delete-department";
  import changeMode from "./change-mode";
  import chooseDefaultPower from "../choose-default-power";
  import changeExam from "@/views/pages/admin/examination/change-exam";
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSearch from "@/components/form/yzSearch.vue";
  import {
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import {
    getCheckPowerPage
  } from "@/api/admin/exam/checkPower";
  import fastImport from '../fast-import'
  import {
    departmentInit
  } from "@/api/admin/exam/checkPower";
  import {getStore, setStore} from "../../../../../libs/storage";

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      addDepartment,
      changeMode,
      changeExam,
      deleteDepartment,
      chooseDefaultPower,
      addDepartmentDiy,
      fastImport,
      YzSearch,
    },
    data() {
      return {
        title: "考试报名信息审核  / ",
        subTitle: "切换报名",
        checDiv: false,
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "审核单位",
            active: true,
          },
        ],
        pageData: {
          pageNum: 1,
          pageSize: 10,
        },
        dataList: [],
        show1: false,
        show2: false,
        show3: false,
        ksInfo: {},
        defaultDw: {},
        useDefault: false,

        search: [],

        isIndeterminate: false,
        checkAll: false,
        checkedCities: []
      };
    },
    methods: {
      //  获取单位列表
      getList() {
        this.pageData.ksbmbh = this.ksInfo.ksbmbh;
        getCheckPowerUnitsPage(this.pageData).then((res) => {
          if (res.status) {
            this.dataList = res.data;
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        });
      },
      deleteAll(){
        deleteAll(this.pageData).then(res=>{
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
        })
      },
      exportExcel() {
        let params = JSON.parse(JSON.stringify(this.pageData))
        params.isExport = true
        getCheckPowerPage(params)
      },
      // 删除
      deleteItem(dwm, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteCheckPowerUnitsByDwdm(this.ksInfo.ksbmbh, dwm).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        });
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        setStore("dpNum",val)
        this.getList();
      },
      editItem(item, show) {
        this.useDefault = true;
        this.defaultDw = {
          jgmc: item.jgmc,
          jgdm: item.jgdm,
          dwdm: item.dwdm,
          dwmc: item.dwmc,
          bshdwmc: item.bshdwmc,
          sid: item.sid,
          cjlx: item.cjlx,
        };
        show.show()
      },
      addItem(show) {
        this.useDefault = false;
        show.show()
      },
      //获取切换的考试
      getLastExam() {
        getNowExam().then(res => {
          if (res.status) {
            this.ksInfo = res.data
            this.getList()
          }
        })
      },
      // 重置或初始化
      departmentInit() {
        this.$confirm('将清空该考试原有的审核单位数据，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          departmentInit("1").then(res => {
            if (res.status) {
              this.$notify.success("重置成功");
              this.getList()
            }
          })
        })
      },

      handleCheckedCitiesChange() {
        console.log(this.checkedCities)
      },
      handleCheckAllChange(e) {
        this.isIndeterminate = e
        this.checkedCities = e ? this.dataList.map(item => item.dwdm) : []
      }
    },

    mounted() {
      this.getLastExam()
      let num=getStore('dpNum')
      let flag=this.$route.query.flag ? this.$route.query.flag : "";
      if(flag){
        this.pageData.pageNum=Number(num)
      }
      getDicts("shdw", "jgxzlx").then((res) => {
        this.dict = res;
        this.search = [{
            title: "机构",
            bound: ["dwdm"],
            type: "select",
            dict: JSON.parse(JSON.stringify(res.shdw)),
          },
          {
            title: "创建类型",
            bound: ["cjlx"],
            type: "select",
            dict: JSON.parse(JSON.stringify(res.jgxzlx)),
          },
          {
            title: "单位编号/单位名称",
            bound: "keyword",
            type: "input",
          },
        ];
      });
    },
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksInfo.ksmc" :subTitle="subTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3" style="display: flex; align-items: center">
              <div style="margin-right: 10px">
                审核方式：本考试采用审核模式为
                <span style="color: red">按报考单位</span> 审核
              </div>
              <b-button style="line-height: 15px" variant="outline-info" class="flexList mr-2 condition"
                @click="$refs.changeMode.show()">更换审核方式
              </b-button>
              <b-button style="line-height: 15px" variant="outline-info" class="flexList mr-2 condition"
                @click="$refs.chooseDefaultPower.show()">设置默认权限
              </b-button>
              <b-button variant="outline-info" class="flexList mr-2 condition" style="line-height: 15px"
                @click="addItem($refs.addDepartment)">
                <i i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加审核单位
              </b-button>
              <b-button variant="outline-info" class="flexList mr-2 condition " style="line-height: 15px"
                @click="addItem($refs.addDepartmentDiy)">
                <i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>自定义添加审核单位
              </b-button>
              <b-button variant="outline-info" class="flexList mr-2 condition" style="line-height: 15px"
                @click="departmentInit()"><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>按主管部门/单位初始化
              </b-button>
            </div>
            <div class="check-table-top">
              <div class="flexList" style="flex: 1">
                <yz-search :data="search" v-model="pageData" @seachList="getList"></yz-search>

                <b-button variant="outline-info" class="flexList mr-2 condition" @click="$refs.fastImport.show()"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>导入审核名单
                </b-button>
                <b-button variant="outline-info" class="flexList mr-2 condition"
                  @click="deleteItem(checkedCities.join(','))"><i
                    class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i>批量删除
                </b-button>
                <b-button variant="outline-info" class="flexList mr-2 condition"
                          @click="deleteAll()"><i
                    class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i>删除空数据
                </b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" @click="exportExcel"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
              </div>
            </div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <div class="table-responsive border mt-3">
                <table class="table light-table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 3%">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                        </el-checkbox>
                      </th>
                      <th style="width: 6%">审核编号</th>
                      <th style="width: 10%">审核单位名称</th>
                      <th style="width: 8%">账号（用/总）</th>
                      <th style="width: 8%">创建类型</th>
                      <th style="width: 10%">被审核单位</th>
                      <th style="width: 70px;">被审单位数</th>
                      <th>被审核职位</th>
                      <th style="width: 3%">新增</th>
                      <th style="width: 3%">删除</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in dataList" :key="i">
                      <td>
                        <el-checkbox :label="item.dwdm" :key="i">{{(pageData.pageNum - 1) * pageData.pageSize +  i + 1}}
                        </el-checkbox>
                      </td>
                      <td>{{ item.dwdm }}</td>
                      <td>
                        <router-link :to="{
                            path: '/admin/examinationAuthority',
                            query: {
                              dwmc: item.dwmc,
                              dwdm: item.dwdm,
                              ksbmbh: ksInfo.ksbmbh,
                              ksmc: ksInfo.ksmc,
                              shfl: 1,
                            },
                          }">{{ item.dwmc }}
                        </router-link>
                      </td>
                      <td>{{ item.syrs }}/{{ item.zrs }}</td>
                      <td>{{ item.cjlx }}</td>
                      <td>
                        <a href="javascript:;" @click="
                            editItem(item, item.bshdwmc ? $refs.deleteDepartment : $refs.addDepartment)
                          ">
                          {{
                        item.bshdwmc ? item.bshdwmc : "请点击添加被审查单位"
                      }}
                        </a>
                      </td>
                      <td style="line-height: 20px;text-align: center;">
                        {{item.bshdwmc ? item.bshdwmc.split(',').length : 0}}
                      </td>
                      <td style="line-height: 20px;padding: 5px;">{{ item.bshzwmc }}</td>
                      <td>
                        <a href="javascript:;" @click="editItem(item, $refs.addDepartment)">
                          <i class="iconfont icon-edit-two text-dark align-middle mr-1"></i>
                        </a>
                      </td>
                      <td class="tab-icon">
                        <i @click="deleteItem(item.dwdm,item.dwmc)"
                          class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-checkbox-group>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <changeExam></changeExam>

    <addDepartment ref="addDepartment" :useDefault="useDefault" :defaultDw="defaultDw" :ksInfo="ksInfo"
      @success="getList"></addDepartment>
    <addDepartmentDiy ref="addDepartmentDiy" :useDefault="useDefault" :defaultDw="defaultDw" :ksInfo="ksInfo"
      @success="getList"></addDepartmentDiy>
    <deleteDepartment ref="deleteDepartment" :useDefault="useDefault" :defaultDw="defaultDw" @success="getList"
      :ksInfo="ksInfo"></deleteDepartment>
    <changeMode ref="changeMode"></changeMode>
    <fastImport ref="fastImport" @success="getList"></fastImport>
    <chooseDefaultPower ref="chooseDefaultPower"></chooseDefaultPower>
  </Layout>
</template>
