<template>
  <b-modal
      v-model="showUser"
      centered
      title="编辑被审核单位"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label
      >{{ ksInfo.ksmc }}{{ useDefault ? "/" + defaultDw.dwmc : "" }}</label
      >
    </div>
    <div class="table-responsive border mt-3">
      <el-tag
          style="margin: 2px; cursor: pointer"
          v-for="(tag, i) in deleteList"
          :key="i"
          closable
          @close="closeTag(i, deleteSidList[i])"
          :type="typeList[i]"
      >
        {{ tag }} {{deleteSidList[i]}}
      </el-tag>
    </div>
    <div class="table-responsive border mt-3" v-if="showList">
      <el-checkbox-group
          v-model="chooseDepartment"
          style="max-height: 300px; overflow-y: auto"
      >
        <table class="table light-table table-hover">
          <thead class="thead-light">
          <tr>
            <th style="width: 3%">
              <el-checkbox :indeterminate="isIndeterminate" v-model="selectAll" @change="handleCheckAllChange"></el-checkbox>
            </th>
            <th style="width: 5%">职位码</th>
            <th style="width: 6%">职位</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(obj, index) in tableList" :key="index">
            <td>
              <el-checkbox @change="chooseChange(obj)" :label="obj" class="mr-1">{{
                  index + 1
                }}</el-checkbox>
            </td>
            <td>{{ obj.zwbm }}</td>
            <td>{{ obj.zwmc }}</td>
          </tr>
          </tbody>
        </table>
      </el-checkbox-group>
    </div>
    <div class="mt-3 text-center">
      <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="deleteStart"
      >
        确定提交
      </button>
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {deleteCheckPowerUnits} from "@/api/admin/exam/checkPower";
import {departmentPosList} from "@/api/admin/exam/checkPower";
import {departmentPosAllList} from "@/api/admin/exam/checkPower";
import {addOrDeleteDepartmentPos} from "@/api/admin/exam/checkPower";
import {addAllDepartmentPos, deleteAllDepartmentPos} from "../../../../../api/admin/exam/checkPower";

export default {
  props: {
    useDefault: {
      default: false,
    },
    defaultDw: {
      default: {},
    },
    ksInfo: {
      default: {},
    },
  },
  data() {
    return {
      deleteList: [],
      deleteSidList: [],
      deleteTaskList: [],
      showUser: false,
      chooseDepartment: [],
      isIndeterminate: false,
      selectAll: false,
      showList: false,
      typeList: [],
      currentUid: "",
      tableList: [],
    };
  },
  methods: {
    show() {
      departmentPosAllList().then(res=>{
        if (res.status){
          this.tableList = res.data
          this.showUser = true
          this.currentUid = ""
          this.showList = false
        }
      })
    },
    hide() {
      this.showUser = false
    },
    closeTag(i, e) {
      this.deleteList.splice(i, 1);
      this.deleteSidList.splice(i, 1);
      this.deleteTaskList.push(e);
      this.currentUid = ""
      this.showList = false
      for (let j = 0; j < this.typeList.length; j++) {
        this.typeList[j] = 'primary'
      }
    },
    deleteStart() {
      if (this.deleteTaskList.length == 0){
        this.hide()
        return;
      }
      this.$confirm(`是否确认提交?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCheckPowerUnits(this.deleteTaskList.join(",")).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.hide()
            this.$emit("success");
          }
        });
      });
    },
    chooseChange(obj){
      obj['uid'] = this.currentUid;
      obj['zymc'] = obj.zwmc;
      obj['zydm'] = obj.zwbm;
      addOrDeleteDepartmentPos(obj).then(res=>{
        if (res.status){
          delete obj['sid']
          this.$notify.success("操作成功")
          this.getZwListByUid()
        }
      })
    },
    getZwList(i){
      this.currentUid = this.deleteSidList[i]
      for (let j = 0; j < this.typeList.length; j++) {
        if (j == i){
          this.typeList[j] = 'danger'
        } else {
          this.typeList[j] = 'primary'
        }
      }
      this.getZwListByUid()
    },
    getZwListByUid(){
      departmentPosList(this.currentUid).then(res=>{
        if (res.status){
          this.showList = true
          this.chooseDepartment = []
          res.data.forEach(i1=>{
            let cur = this.tableList.find(i2 => {
              return i2.zwbm == i1.zydm;
            })
            if (cur) {
              cur['sid'] = i1.sid
              this.chooseDepartment.push(cur)
            }
          })
          this.$forceUpdate()
        }
      })
    },
    handleCheckAllChange(e){
      this.isIndeterminate = e
      this.chooseDepartment = e?this.tableList:[]
      if (e){
        let chooseList = this.chooseDepartment.map(obj=>{
          obj['zymc'] = obj.zwmc;
          obj['zydm'] = obj.zwbm;
          return obj;
        })
        addAllDepartmentPos(this.currentUid, chooseList).then(res=>{
          if (res.status){
            this.$notify.success("操作成功")
            this.getZwListByUid()
          }
        })
      } else {
        deleteAllDepartmentPos(this.currentUid).then(res=>{
          if (res.status){
            this.$notify.success("操作成功")
            this.getZwListByUid()
          }
        })
      }
    }
  },
  components: {},
  mounted() {
  },
  watch: {
    "defaultDw.bshdwmc": {
      deep: true,
      handler(e) {
        if (e) {
          this.deleteList = e.split(",");
        }

      },
    },
    "defaultDw.sid": {
      deep: true,
      handler(e) {
        if (e) {
          this.deleteSidList = e.split(",");
          this.typeList = []
          this.deleteSidList.forEach(_=>{
            this.typeList.push('primary')
          })
        }
      },
    },
  },
};
</script>

<style></style>
